import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {toast} from 'react-toastify'
import { VerifyPayments } from "../../api/student";
import { getCurrentUser } from "../../utils/authService";

function PaymentVerification() {
    const [urlParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false)
    // const [ref, setRef] = useState("")

    const user = getCurrentUser()
    
    const ref = urlParams.get('reference');

    // const payApplicationFee = async () => {
    //     try {
    //       const {data} = await InitiateApplicationPayment({
    //         application_id: user.application_id
    //       })
    //       setRef(data.reference);
    //     } catch (error) {
    //       console.log(error);
          
    //     }
    //   }


    const errorProp = {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      };

    const verifyPayment = async () => {
        try {
            const response = await VerifyPayments(ref);
            navigate('/successful', {replace: true});
        } catch (error) {
        }
    }
    
    // useEffect(() => {
    //     payApplicationFee()
    // }, []);

    useEffect(() => {
        verifyPayment();
    }, [ref]);

    if (ref === "") return null

    return (
        <>
            <center>
                {/* <div
                    class="preloader pl-size-xs"
                    style={{ marginTop: "100px" }}
                >
                    <div class="spinner-layer center">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div> */}
                <div class="page-title-box">
                    <h4 class="page-title">Verifying payment...</h4>
                </div>
            </center>
        </>
    );

}

export default PaymentVerification;
