import React from 'react'
import { File, Send } from 'react-feather'
import {BsBook} from "react-icons/bs"
import { useNavigate } from 'react-router-dom'

const CourseRegCard = ({title, stage, firstSemester, secondSemester}) => {
    const navigate = useNavigate()

  return (
    <div class="card report-card">
        <div class="card-body">
            <div class="row d-flex justify-content-center">
                <div class="col-8">
                    <p class="text-dark font-weight-semibold font-14">{title}</p>
                        <h3 class="my-3" disabled>{(!firstSemester?.length && !secondSemester?.length) ? 'NO' :
                                                    (firstSemester?.length && !secondSemester?.length) ? 'Started' :
                                                    (firstSemester?.length && secondSemester?.length) ? 'Completed' :''}</h3>
                </div>
                <div class="col-4 align-self-center">
                    <div class="report-main-icon bg-light-alt">
                        <File class="align-self-center icon-dual-pink icon-lg"></File>  
                    </div>
                </div>
            </div>
        </div> 
    </div>
  )
}

export default CourseRegCard