import img1 from "../assets/images/student.jpg"
import img2 from "../assets/images/rems.jpg"

const SliderCarousel = () => {
  return (
    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
  <ol className="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
  </ol>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img className="d-block w-100" src="https://source.unsplash.com/NIJuEQw0RKg" style={{ height: "800px" }} alt="First slide"/>
      <div class="carousel-caption d-none d-md-block">
  </div>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="https://source.unsplash.com/2JIvboGLeho" style={{ height: "800px" }} alt="Second slide"/>
    </div>
    <div className="carousel-item">
      <img className="d-block w-100" src="https://source.unsplash.com/OE_xWBQaZu0" style={{ height: "800px" }} alt="Third slide"/>
    </div>
  </div>
  <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>
    
  )
}

export default SliderCarousel