import { useEffect, useState } from "react"
import { getCurrentUser } from "../utils/authService";
import api from "../api/api";


const usePayments = () => {
    const [payment, setPayment] = useState()
    const [loading, setLoading] = useState(false);
    const user = getCurrentUser();

    useEffect(() => {
        const getPayments = async () => {
            setLoading(true);
            try {
              const {data} = await api.get(`/student/payment-info/${user}`);
              console.log("Student Payment", data?.data);
              setPayment(data?.data)
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
          };
          getPayments()
    }, [])

    return {payment}
}

export default usePayments