import React, { UseEffect, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate, useSearchParams, Navigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import NavHeader from "../components/NavHeader";
import { URL } from "../utils/appParams";

const NewPassword = () => {
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const [btnText, setBtnText] = useState("Submit");
  const [loading, setLoading] = useState(false);
  const [alertClose,setAlertClose] = useState(false)
  const [reset, setReset] = useState(false)

  const navi = useNavigate();
  const location = useLocation()

  const {token, uidb64} = useParams()
  console.log("paramss", token, uidb64)

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const newPassword = async (e) => {
    e.preventDefault();
    if (!uidb64 || !token) return toast.info("Click on the verification link sent to your mail")
    if (password !== password2) return toast.warning("Password do not match")
    setBtnText('Loading...')
    setLoading(true)
    try {
        const {data} = await axios.patch(URL +'/password/password-reset-complete', {
            password,
            token,
            uidb64
        })
        console.log(data)
        setAlertClose(true)
        setBtnText("Submit")
        toast.success(data?.message)
        setLoading(false)
        setReset(true)
    } catch (error) {
      console.log(error);
      setBtnText("Failed, try again")
      setLoading(false)
      toast.error(error.response.data.error, errorProp)
    }
  };

  if (reset) {
    return (
      <Navigate
        to={{
          pathname: `/apply/login`,
          state: { from: location },
        }}
        replace
      />
    );
  }

  return (
    <>
      <NavHeader />
      <div class="account-body">
        <div class="container">
          <div class="row vh-100 ">
            <div class="col-12 align-self-center">
              <div class="auth-page">
                <div class="card auth-card shadow-lg">
                  <div class="card-body">
                    <div class="px-3">
                      <div class="text-center auth-logo-text">
                        <h4 class="mt-0 mb-3 mt-5">Reset Your Password</h4>
                        <p class="text-muted mb-0">
                          Provide new password to reset password!
                        </p>
                      </div>

                      <form
                        class="form-horizontal auth-form my-4"
                        onSubmit={newPassword}
                      >
                        <div class="form-group">
                          <label for="username">Password</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-lock"></i>
                            </span>
                            <input
                              type="password"
                              class="form-control"
                              placeholder="Enter password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="username">Confirm password</label>
                          <div class="input-group mb-3">
                            <span class="auth-form-icon">
                              <i class="dripicons-lock"></i>
                            </span>
                            <input
                              type="password"
                              class="form-control"
                              placeholder="Confirm password"
                              value={password2}
                              onChange={(e) => setPassword2(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group mb-0 row">
                          <div class="col-12 mt-2">
                            <button
                              class="btn btn-gradient-primary btn-round btn-block waves-effect waves-light"
                              type="submit"
                              disabled={loading}
                            >
                              {btnText} <i class="fas fa-sign-in-alt ml-1"></i>
                            </button>
                          </div>
                        </div>
                        <ToastContainer />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
