import React, {useEffect} from "react";
import {useNavigate} from 'react-router-dom'
import image from "../assets/images/check.png"

function SuccessPayment() {

    const  navigate = useNavigate()

    const printReceipt = (e) => {
        e.preventDefault()
        navigate('/admission/acceptance-receipt', {replace: true})
    }

    useEffect(() => {
      const timer = setTimeout(() => {
        navigate(-1, {replace: true})
      }, 3000)
    
      return () => {
        clearTimeout(timer)
      }
    }, [])
    

  return (
    <>
      <center className="card" style={{ height: "100vh" }}>
        <div
          className=""
          style={{ color: "#45f248", fontFamily: "poppins", marginTop:'9rem' }}
        >
          <img alt="success" height='100px' src={image}/>
          <h2 style={{ fontSize: "2rem" }}>Payment successful</h2>
        </div>
      </center>
    </>
  );
}

export default SuccessPayment;
